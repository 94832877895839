.gel4-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.gel4-header__logo {
    display: flex;
    align-items: center;
}

.gel4-header__logo-image {
    height: 40px;
    margin-right: 10px;
}

.gel4-header__title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
}

.gel4-header__user-info {
    display: flex;
    align-items: center;
}

.gel4-header__user-name {
    font-size: 1rem;
    color: #343a40;
}