* {
  box-sizing: border-box;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.fuelplan_wrapper_sub{
	display: flex;
	justify-content: space-between;
 }


.fp_label_value{
  display: flex;
	justify-content: space-between;
  background: rgb(60, 77, 93);
    color: #fff;
    margin-bottom: 20px;
    padding: 20px;
}

.fuelplan_wrapper{
  margin-bottom: 20px;
}


.fuel_container {
 display: flex;
 justify-content: center;
  background: rgb(5, 16, 27);
  color: white;
  padding: 30px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
}
.fuel_sub_container {
  max-width: 600px;
  width: 100%;
}

.fuel_container .inputContainer {
  min-width: 500px;
  color: gray;
}

.fuel_container .inputTxt {
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
  font-size: 18px;
}

.fuel_container label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.fuel_container input {
  height: 50px;
  border-radius: 8px;
  margin-bottom: 15px;
  background: rgb(60, 77, 93);
}

.fuel_container .refreshButton {
  background-color: blue;
  color: red;
  padding: 15px 70px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-weight: 700;
  margin: 0 auto;
}

.large_font {
  font-size: 1.3em; /* Adjust the size as needed */
}

.bold_heading {
  font-weight: bold;
}
.highlight {
  background-color: yellow; /* or any other highlight color */
}

.btn.current_fuel_reading_btn {
  color: #fff !important;
    background-color: #008291;
    border-color: #008291;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}